import React from "react"
import { Container, Div, Image, Text } from "atomize"
import { PrimaryColor } from "../components/colors"
import logo from "../images/logo.svg"
import LeadsForm from "../components/LeadsForm"

const FormPage = () => {
  return (
    <div>
      <div style={{ height: "48px" }} />
      <Div cursor="pointer" w="100%" align="center" justify="center" d="flex">
        <a href={"../"}>
          <Image src={logo} alt="ucall logo" h="56px" w="auto" />
        </a>
      </Div>
      <Container d="flex" flexDir={"column"}>
        <Text
          tag="h3"
          textWeight="700"
          textAlign="left"
          textSize="display5"
          fontFamily="secondary"
          textColor={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Liên hệ tư vấn
        </Text>
        <LeadsForm />
      </Container>
    </div>
  )
}

export default FormPage
